import React, { Component } from 'react'
import Link from 'gatsby-link';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { getLanguageFromPath } from 'utils/routing'
import slugify from 'slugify'

export default class SearchResult extends Component {
    results = []

    getRelevantPageCopy = _ => {
        const {
            searchTerm,
            result,
        } = this.props

        const allContentBlocks = result.introduction ? result.introduction.contentBlocks : []

        allContentBlocks.forEach(contentBlock => {
            const str = contentBlock.content ? documentToPlainTextString(contentBlock.content.json) : null

            if (str) {
                str.split(/[.?!]/).filter(line => {

                    if (line.toLowerCase().includes(searchTerm) == true) {
                        const string = line.toLowerCase()

                        this.results.push(string.replace(searchTerm, '<strong>' + searchTerm + '</strong>'))
                    } else {
                        const allTableRows = contentBlock.table ? contentBlock.table.tabs : []

                        allTableRows.forEach(tableRow => {
                            const tableRowContent = tableRow.content ? documentToPlainTextString(tableRow.content.json) : null

                            let foundSearchTerm = false

                            if (tableRowContent && foundSearchTerm == false) {
                                tableRowContent.split(/[.?!]/).filter(line => {

                                    if (line.toLowerCase().includes(searchTerm) == true) {
                                        const string = line.toLowerCase()

                                        this.results.push(string.replace(searchTerm, '<strong>' + searchTerm + '</strong>'))

                                        foundSearchTerm = true
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    getUrl = _ => {
        const {
            location,
            result
        } = this.props

        const currentLanguage = getLanguageFromPath(location.pathname).toLowerCase()

        if(!result.parentPage || !result.englishTitle) {
            return
        }

        const parentSlug = slugify(result.parentPage).toLowerCase()
        const thisSlug = slugify(result.englishTitle).toLowerCase()

        return `/${currentLanguage}/${parentSlug}/${thisSlug}`
    }

    render() {
        const {
            result,
        } = this.props

        this.results = []

        this.getRelevantPageCopy()
        const url = this.getUrl()

        return (
            <li>
                <Link to={url}>
                    <h2>
                        {result.title}
                    </h2>
                    <div dangerouslySetInnerHTML={{ __html: this.results[0] + '...' }} />
                </Link>
            </li>
        )
    }

}
